<template>
  <div>
    <data-table
      :headers="headers"
      :items="members"
      :expanded-menu-items="expandedMenuItems"
    >
      <template v-slot:display_name="{ props }">
        <div>
          <avatar :image="props.item.avatar" size="small"></avatar>
          <span class="user-name">
            {{ props.item.display_name }}
          </span>
        </div>
      </template>
      <template v-slot:joined="{ props }">
        {{ getFormatTime(props.item.member_relationship.created_on) }}
      </template>

      <template v-slot:role="{ props }">
        {{ getMemberRole(props.item.member_relationship.is_admin) }}
      </template>

      <template v-slot:expanded-row-content="{ item }">
        <user-detail :user="item" />
      </template>
    </data-table>
    <confirm-dialog
      v-model="displayDialog"
      :title="dialogTitle"
      :buttonList="dialogButtonList"
      :description="dialogDescription"
      :dialogKey="dialogKey"
    />
  </div>
</template>
<script>
import moment from "moment";
import Avatar from "../../app/components/avatars/Avatar.vue";
import UserGeneralDetailVue from "../../users/components/UserGeneralDetail.vue";
import ConfirmDialog from "../../app/components/dialogs/ConfirmDialog.vue";
import DataTable from "../../app/components/tables/DataTable.vue";
import {
  editMemberRelationship,
  removeMemberRelationship,
} from "../utils/org-member-request";

export default {
  components: {
    avatar: Avatar,
    "user-detail": UserGeneralDetailVue,
    "data-table": DataTable,
    "confirm-dialog": ConfirmDialog,
  },
  data() {
    return {
      displayDialog: false,
      dialogTitle: "",
      dialogDescription: "",
      dialogButtonList: [
        {
          label: "Accept",
          color: "inqliGreen",
          argument: "'",
          onClick: null,
        },
        {
          label: "Cancel",
          color: "inqliRed",
          argument: null,
          onClick: () => (this.displayDialog = false),
        },
      ],
      dialogKey: "",
    };
  },
  props: {
    members: {
      type: Array,
      required: true,
    },
    orgId: {
      type: String,
      required: true,
    },
    currentUserRelationship: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isAdmin() {
      return (
        this.currentUserRelationship.is_admin ||
        this.$store.state.user.isSystemAdmin
      );
    },
    headers() {
      return [
        { text: "Name", align: "left", value: "display_name" },
        { text: "Email", align: "left", value: "email" },
        {
          text: "Role",
          align: "left",
          value: "role",
        },
        {
          text: "Joined",
          align: "left",
          value: "joined",
        },
      ];
    },
    expandedMenuItems() {
      return [
        {
          label: "View profile",
          ariaLabel: "view-profile",
          icon: "mdi-account-details",
          action: this.visitMemberProfile,
        },
        {
          label: "Edit role",
          ariaLabel: "edit-role",
          icon: "mdi-circle-edit-outline",
          action: this.openEditRoleDialog,
          hide: !this.isAdmin,
        },
        {
          label: "Remove member",
          ariaLabel: "remove-member",
          icon: "mdi-delete",
          action: this.openRemoveMemberDialog,
          hide: !this.isAdmin,
        },
      ];
    },
  },
  methods: {
    getMemberRole(isAdmin) {
      return isAdmin ? "Admin" : "Member";
    },
    getFormatTime(createdOn) {
      return moment.utc(createdOn).local().format("MMM Do YYYY");
    },
    visitMemberProfile(user) {
      this.$router.push(`/users/${user.id}`);
    },
    openDialog({
      title,
      description,
      buttonAcceptArgument,
      buttonAcceptOnClick,
      buttonAcceptLabel,
      dialogKey,
    }) {
      this.dialogTitle = title;
      this.dialogDescription = description;
      this.dialogButtonList[0] = {
        ...this.dialogButtonList[0],
        argument: buttonAcceptArgument,
        onClick: buttonAcceptOnClick,
        label: buttonAcceptLabel,
      };
      this.dialogKey = dialogKey;
      this.displayDialog = true;
    },
    openRemoveMemberDialog(member) {
      const memberId = member.id;
      this.openDialog({
        title: `Remove ${member.display_name}?`,
        description: "This action can not be undone.",
        buttonAcceptLabel: "Remove Anyway",
        buttonAcceptArgument: memberId,
        buttonAcceptOnClick: this.removeMember,
        dialogKey: memberId,
      });
    },
    openEditRoleDialog(member) {
      const memberId = member.id;
      if (memberId === this.$store.state.user.username) {
        this.$store.dispatch("callAlert", "You can not edit your role.");
        return;
      }
      this.openDialog({
        title: `Edit role?`,
        description: `Are you sure you want to ${
          member.member_relationship.is_admin ? "unassign" : "assign"
        } ${member.first_name} admin role?`,
        buttonAcceptLabel: "Accept",
        buttonAcceptArgument: member,
        buttonAcceptOnClick: this.toggleAdminAssignment,
        dialogKey: memberId,
      });
    },
    async removeMember(memberId) {
      try {
        await removeMemberRelationship(memberId, this.orgId);
        this.$store.dispatch(
          "callAlert",
          "Member has been removed successfully."
        );
        this.$emit("reload");
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while removing chosen member."
        );
      }
      this.displayDialog = false;
    },
    async assignAdminToMember(memberId) {
      try {
        await editMemberRelationship(memberId, this.orgId, {
          is_admin: true,
        });
        this.$store.dispatch(
          "callAlert",
          "Member has been assigned as admin successfully."
        );
        this.$emit("reload");
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while sending request."
        );
      }
      this.displayDialog = false;
    },
    async unAssignAdminToMember(memberId) {
      try {
        await editMemberRelationship(memberId, this.orgId, {
          is_admin: false,
        });
        this.$store.dispatch(
          "callAlert",
          "Member has been unassigned from admin role successfully."
        );
        this.$emit("reload");
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while sending request."
        );
      }
      this.displayDialog = false;
    },
    toggleAdminAssignment(memberInfo) {
      if (memberInfo.member_relationship.is_admin) {
        this.unAssignAdminToMember(memberInfo.id);
      } else {
        this.assignAdminToMember(memberInfo.id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.user-name {
  font-weight: 600;
  margin-left: 10px;
  color: $inqliNavy;
}
</style>
