<template>
  <layout>
    <template #header>
      <app-header>
        <pagination
          :current-page="currentPage"
          :total-pages="totalPages"
          @update="updatePage"
        />
      </app-header>
    </template>
    <div class="content">
      <member-table
        v-if="members.length >= 1"
        :members="members"
        :org-id="orgId"
        @reload="getMemberList"
        :current-user-relationship="currentUserRelationship"
      />
    </div>
  </layout>
</template>
<script>
import Pagination from "../app/components/Pagination.vue";
import MemberTable from "./components/MemberTable.vue";
import Header from "../app/components/headers/DefaultHeader";
import DefaultLayout from "../app/components/layouts/DefaultLayout";
import { getMemberList } from "./utils/org-member-request";

export default {
  name: "OrgMember",
  metaInfo: {
    title: "Member List",
  },
  components: {
    "member-table": MemberTable,
    "app-header": Header,
    layout: DefaultLayout,
    pagination: Pagination,
  },
  data() {
    return {
      members: [],
      currentPage: 1,
      totalPages: 10,
      limit: 10,
      currentUserRelationship: {},
    };
  },
  async mounted() {
    const data = await this.getMemberList();
    if (data) {
      const currentUser = data.list.find((member) => {
        return member.id === this.$store.state.user.username;
      });
      // only allow members of the org and system admin to access this page
      if (currentUser) {
        this.currentUserRelationship = currentUser.member_relationship;
      } else if (!this.$store.state.user.isSystemAdmin) {
        this.$router.push("/");
        this.$store.dispatch(
          "callAlert",
          "You are not a member of this organization"
        );
      }
    }
  },
  computed: {
    orgId() {
      return this.$route.params.orgId;
    },
  },
  methods: {
    async updatePage(page) {
      this.currentPage = page;
      await this.getMemberList();
    },
    async getMemberList() {
      const orgId = this.orgId;
      try {
        const data = await getMemberList(orgId, {
          page: this.currentPage,
          limit: this.limit,
          include: "member_relationship",
        });
        this.totalPages = data.total_pages;
        this.members = data.list;
        return data;
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting user list"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  padding: 15px 40px;
  width: 80%;
  margin: 0 auto;
}
.button-list {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
@media (max-width: $breakpoint-tablet-large) {
  .content {
    padding: 10px;
    width: 100%;
  }
}
</style>
