var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "app-header",
                [
                  _c("pagination", {
                    attrs: {
                      "current-page": _vm.currentPage,
                      "total-pages": _vm.totalPages
                    },
                    on: { update: _vm.updatePage }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.members.length >= 1
            ? _c("member-table", {
                attrs: {
                  members: _vm.members,
                  "org-id": _vm.orgId,
                  "current-user-relationship": _vm.currentUserRelationship
                },
                on: { reload: _vm.getMemberList }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }